<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <h3>{{ $t('donBox.nativeCampaign.brandsTable.title') }}</h3>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th> {{ $t('donBox.nativeCampaign.brandsTable.brands') }} </th>
          <th class="text-right"> {{ $t('donBox.nativeCampaign.brandsTable.visits') }} </th>
          <th class="text-right"> {{ $t('donBox.nativeCampaign.brandsTable.clicks') }} </th>
          <th class="text-right"> {{ $t('donBox.nativeCampaign.brandsTable.ctr') }} </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in brandStats" :key="`item-${index}`">
          <td class="text-left">{{ row.brand }}</td>
          <td class="text-right">{{ row.inviewImpressions | formatNumber }}</td>
          <td class="text-right">{{ row.clicks | formatNumber }}</td>
          <td class="text-right">{{ row.ctr | formatPercentage }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'NativeCampaignAnalyticsTable',
  props: {
    brandStats: {
      type: Array
    }
  }
}
</script>

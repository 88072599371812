<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <h3>{{ $t('don.npa.daily_stats_chart') }}</h3>
      </div>
      <div class="col-lg-6 text-right">
        <ButtonCsvExport :data="getExportData()" filename="campaignProgress" />
      </div>
    </div>
    <Select
      id="alternative"
      v-model="alternative"
      :options="alternatives"
      class="alternatives-select"
      :empty-value-title="$t('donBox.nativeCampaign.mainAlternative')"
      disable-form-group
    />
    <div>
      <LineChart
        :chart-data="analyticsChartData"
        :height="330"
        :max-labels="31"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import LineChart from '@/components/chart/LineChart'
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'
import Select from '@/components/form/select/Select'

export default {
  name: 'NativeCampaignAnalyticsChart',
  props: {
    trendStats: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      alternative: null
    }
  },
  components: {
    LineChart,
    ButtonCsvExport,
    Select
  },
  computed: {
    overallData () {
      if (this.alternative) {
        return this.trendStats.map(stat => (stat.alternatives.find(alt => alt.alternative === this.alternative) ?? {}))
      }
      return this.trendStats.map(stat => stat.overall)
    },
    timeGroups () {
      return this.trendStats.map(stat => stat.day)
    },
    alternatives () {
      if (this.trendStats?.length > 0 && this.trendStats[0].alternatives?.length > 0) {
        return this.trendStats[0].alternatives.map(alt => (
          {
            id: alt.alternative,
            title: this.$t('donBox.nativeCampaign.alternativeName', { name: alt.alternative })
          }))
      }
      return []
    },
    analyticsChartData () {
      if (this.overallData === null) {
        return {
          labels: [],
          datasets: []
        }
      }

      const datasets = [
        {
          label: 'In Views ',
          borderColor: '#1693c1',
          backgroundColor: 'rgba(22, 147, 193, 0.4)',
          fill: true,
          data: this.overallData.map(overall => overall.inviewImpressions)
        },
        {
          label: 'Clicks ',
          borderColor: '#ffb22b',
          backgroundColor: 'rgba(255, 178, 43, 0.5)',
          fill: true,
          data: this.overallData.map(overall => overall.clicks)
        },
        {
          label: 'CTR (%)',
          borderColor: '#ffb22b',
          backgroundColor: 'rgba(255, 178, 43, 0.5)',
          fill: true,
          data: this.overallData.map(overall => overall.ctr * 100)
        }
      ]

      return {
        labels: this.formatTimeGroupDates(this.timeGroups),
        datasets
      }
    }
  },
  methods: {
    getExportData () {
      const exportData = []

      this.trendStats.forEach(stat => {
        const { overall, alternatives } = stat
        const day = moment(stat.day).format('DD.MM.YYYY')
        const dataRow = {
          day,
          alternative: 'Main alternative',
          inviewImpressions: overall.inviewImpressions,
          clicks: overall.clicks,
          ctr: overall.ctr * 100
        }
        exportData.push(dataRow)

        if (alternatives) {
          alternatives.forEach(alternative => {
            const dataRow = {
              day,
              alternative: alternative.alternative,
              inviewImpressions: alternative.inviewImpressions,
              clicks: alternative.clicks,
              ctr: alternative.ctr * 100
            }
            exportData.push(dataRow)
          })
        }
      })

      return exportData
    },
    formatTimeGroupDates (timeGroups, format = 'DD.MM.YYYY') {
      return timeGroups.map(timeGroup => moment(timeGroup).format(format))
    }
  }
}
</script>
<style lang="scss" scoped>
.alternatives-select {
  width: 200px;
  margin-bottom: -10px;
  margin-top: -20px;
}
</style>

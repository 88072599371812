<template>
  <section>
    <Preloader v-if="!dataLoaded || callingAPI"/>
    <ModuleFormButton
      v-if="errorOnLoad"
      icon="close"
      @click="$router.push('/nativeCampaignManagement')"
    />
    <ModuleForm
      v-if="dataLoaded && !callingAPI && !errorOnLoad"
      :module="item"
      :show-header-info="false"
    >
      <template #form-title>
        {{ $t(`route.${$route.name}`) }}
      </template>
      <template #header-buttons>
        <ModuleFormButton
          :title="$t('donBox.nativeCampaign.resetCampaign')"
          :tooltip="resetTooltip"
          :disabled="resetIsDisabled"
          :required-permissions="requiredPermissions.resetButton"
          @click="resetCampaign"
        />
        <ModuleFormButton
          v-if="item.status.toLowerCase() === 'done' && !item.duplicate"
          :title="$t('buttons.duplicate')"
          :required-permissions="requiredPermissions.duplicateButton"
          @click="goToDuplicate"
        />
        <ModuleFormButton
          v-if="item.status.toLowerCase() !== 'done'"
          :required-permissions="requiredPermissions.editButton"
          icon="edit"
          @click="goToEdit"
        />
        <ModuleFormDeleteButton
          :record-id="item.campaignId"
          :required-permissions="requiredPermissions.deleteButton"
          action-id-param="campaignId"
          store-name="nativeCampaignManagement"
          list-router-path="nativeCampaignManagement"
        />
        <ModuleFormButton
          icon="close"
          @click="$router.push('/nativeCampaignManagement')"
        />
      </template>
      <template #form-content>
        <div class="card">
          <div class="card-body">
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <th>{{ `ID` }}</th>
                <td>{{ item.campaignId }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.title') }}</th>
                <td>{{ item.title }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.pageUrl') }}</th>
                <td>{{ item.url }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.agency') }}</th>
                <td>{{ item.agency }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.status') }}</th>
                <td>{{ item.status.toUpperCase() }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.salesman') }}</th>
                <td>{{ item.salesman }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.client') }}</th>
                <td>{{ item.client }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.orderId') }}</th>
                <td>{{ item.orderId }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.image') }}</th>
                <td>
                  <Media
                    v-if="mainImage"
                    :media="mainImage"
                    :width="480"
                    :height="270"
                    show-media-info
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.clicksLimit') }}</th>
                <td>{{ item.clicksLimit ? item.clicksLimit : 0 }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.clickPrice') }}</th>
                <td>{{ item.clickPrice | formatCurrency }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.bonusClicks') }}</th>
                <td>{{ item.bonusClicks ? item.bonusClicks : 0  }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.clicks') }}</th>
                <td>{{ (item.clicks ? item.clicks : 0) | formatNumber }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.sumPrice') }}</th>
                <td>{{ item.sumPrice | formatCurrency }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.budgetFulfillment') }}</th>
                <td>{{ item.budgetFulfillment | formatPercentage }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.fulfillment') }}</th>
                <td>{{ item.fulfillment | formatPercentage }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.bonusFulfillment') }}</th>
                <td>{{ item.bonusFulfillment | formatPercentage }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.visits') }}</th>
                <td>{{ (item.inviewImpressions ? item.inviewImpressions : 0) | formatNumber }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.displays') }}</th>
                <td>{{ (item.impressions ? item.impressions : 0) | formatNumber }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.segments') }}</th>
                <td>
                  <template v-if="item.segments.length > 0"> {{ item.segments.join(',') }}</template>
                </td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.brands') }}</th>
                <td>
                  {{ convertedBrands }}
                </td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.category') }}</th>
                <td>{{ getCategoryTitle(item.category) }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.publishedFrom') }}</th>
                <td>{{ item.publishedFrom | prettyDateTime }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.publishedTo') }}</th>
                <td>{{ item.publishedTo | prettyDateTime }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.note') }}</th>
                <td>{{ item.note }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.paidCampaign') }}</th>
                <td>{{ item.isFree ? $t('no') : $t('yes') }}</td>
              </tr>
              <tr>
                <th>{{ $t('system.created_at') }}</th>
                <td>{{ item.createdAt | prettyDateTime }}</td>
              </tr>
              <tr>
                <th>{{ $t('system.modified_at') }}</th>
                <td>{{ item.modifiedAt | prettyDateTime }}</td>
              </tr>
              <tr>
                <th>{{ $t('system.created_by') }}</th>
                <td>{{ userNameById(item.createdBy) }}</td>
              </tr>
              <tr>
                <th>{{ $t('system.modified_by') }}</th>
                <td>{{ userNameById(item.modifiedBy) }}</td>
              </tr>
              </tbody>
            </table>
            <table class="table table-striped table-bordered" v-if="item.schedulingEnabled">
              <tbody>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.campaign_delivery_day') }}</th>
                <td v-html="getDeliveryDay(item.schedulingConfig.deliveryDay)"></td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.campaign_delivered_from') }}</th>
                <td v-html="getDeliveryTime(item.schedulingConfig.deliveryTimeFrom)"></td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.campaign_delivered_to') }}</th>
                <td v-html="getDeliveryTime(item.schedulingConfig.deliveryTimeTo)"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card card-body">
          <h3 class="alternating-campaigns-header">
            {{ $t('donBox.nativeCampaign.alternativesEnabled') }}
          </h3>
          <div v-for="(alternative, index) in alternatives" :key="`alternative-item-${index}`">
            <h4 class="alternating-campaigns-header">
              {{ $t('donBox.nativeCampaign.alternativeName', { name: alternative.name }) }}
            </h4>
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <th width="280">{{ $t('donBox.nativeCampaign.title') }}</th>
                <td>{{ alternative.title }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.image') }}</th>
                <td>
                  <Media
                    v-if="imageAlternatives[index].image"
                    :media="imageAlternatives[index].image"
                    :width="480"
                    :height="270"
                    show-media-info
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.alternative.ctr') }}</th>
                <td>{{ alternative.ctr | formatPercentage }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.alternative.visitsCount') }}</th>
                <td>{{ (alternative.inviewImpressions ? alternative.inviewImpressions : 0) | formatNumber }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.alternative.displaysCount') }}</th>
                <td>{{ (alternative.impressions ? alternative.impressions : 0) | formatNumber }}</td>
              </tr>
              <tr>
                <th>{{ $t('donBox.nativeCampaign.alternative.clickCount') }}</th>
                <td>{{ (alternative.clicks ? alternative.clicks : 0) | formatNumber }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="trendStats.length > 0" class="card">
          <div class="card-body">
            <NativeCampaignAnalyticsChart :trend-stats="trendStats" />
          </div>
        </div>

        <div v-if="brandStats.length > 0" class="card">
          <div class="card-body">
            <NativeCampaignAnalyticsTable :brand-stats="brandStats" />
          </div>
        </div>
      </template>
    </ModuleForm>
  </section>
</template>

<script>
import NativeCampaignModel from '@/model/NativeCampaignModel'
import Preloader from '@/components/preloader/Preloader'
import UserService from '../../services/user/UserService'
import Media from '@/components/shared/Media'
import { CATEGORIES_MAP } from '@/model/ValueObject/NativePerfCategories'
import CoreApi from '@/api/core'
import { DELIVERIES_LIST } from '@/model/ValueObject/NativePerfDeliveries'
import moment from 'moment'
import NativeCampaignAnalyticsChart from '@/components/donBox/NativeCampaignAnalyticsChart'
import NativeCampaignAnalyticsTable from '@/components/donBox/NativeCampaignAnalyticsTable'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import NotifyService from '@/services/NotifyService'
import { mapState } from 'vuex'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'NativeCampaignManagementView',
  data () {
    return {
      item: NativeCampaignModel,
      trendStats: [],
      brandStats: [],
      dataLoaded: false,
      mainImage: null,
      errorOnLoad: null,
      imageAlternatives: [
        {
          name: 'A',
          image: null
        },
        {
          name: 'B',
          image: null
        },
        {
          name: 'C',
          image: null
        },
        {
          name: 'D',
          image: null
        },
        {
          name: 'E',
          image: null
        }
      ],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.NATIVE_CAMPAIGN_MANAGEMENT_PERMISSIONS
    }
  },
  components: {
    Preloader,
    Media,
    NativeCampaignAnalyticsChart,
    NativeCampaignAnalyticsTable,
    ModuleFormDeleteButton,
    ModuleFormButton,
    ModuleForm
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    alternatives () {
      return [this.item.mainAlternative, ...this.item.alternatives]
    },
    resetIsDisabled () {
      return moment().isBefore(this.item.publishedTo)
    },
    resetTooltip () {
      return this.$t(`donBox.nativeCampaign.resetCampaign${this.resetIsDisabled ? 'Disabled' : ''}`)
    },
    convertedBrands () {
      if (this.item.brands?.length > 0) {
        return this.$store.getters['site/mapNamesToSites'](this.item.brands)
          .map(brand => brand.title).join(', ')
      } else {
        return this.$t('donBox.nativeCampaign.allNMHBrandOption')
      }
    }
  },
  methods: {
    getItem () {
      this.$store.dispatch('nativeCampaignManagement/fetchOne', this.$route.params.id)
        .then(async () => {
          if (!this.$store.getters['nativeCampaignManagement/error']) {
            const detail = this.$store.getters['nativeCampaignManagement/detail']
            this.item = detail.data
            this.trendStats = detail.trendStats ?? []
            this.brandStats = detail.brandStats ?? []

            if (this.item.alternatives) {
              await this.loadAlternatives()
            }
            if (this.item.damImageId) {
              await this.fetchMainImage(this.item.damImageId)
            } else {
              this.dataLoaded = true
            }
          } else {
            this.errorOnLoad = true
            this.dataLoaded = true
            NotifyService.setErrorMessage(this.$store.getters['nativeCampaignManagement/error'])
          }
        })
    },
    userNameById (idOrUser) {
      if (typeof idOrUser === 'object') {
        return idOrUser?.name
      }
      const user = this.$store.getters['user/userById'](parseInt(idOrUser))
      if (user) {
        return UserService.getUserInfo(user)
      }
      return idOrUser
    },
    getCategoryTitle (categoryId) {
      return CATEGORIES_MAP[categoryId]
    },
    async loadAlternatives () {
      const imagePromises = this.item.alternatives.reduce((acc, alternative, idx) => {
        if (alternative.damImageId) {
          acc.push(this.fetchArticleAlternativeImage(idx, alternative.damImageId))
        }
        return acc
      }, [])

      await Promise.all(imagePromises)
        .catch(error => console.error(error))
        .finally(() => { this.dataLoaded = true })
    },
    fetchMainImage (damImageId) {
      return CoreApi().get('/image/' + damImageId)
        .then((response) => {
          this.mainImage = response.data
          this.imageAlternatives[0].image = response.data
        })
        .catch(error => console.error(error))
        .finally(() => { this.dataLoaded = true })
    },
    fetchArticleAlternativeImage (index, damImageId) {
      return CoreApi().get('/image/' + damImageId)
        .then((response) => {
          // index 0 is for main alternative
          this.imageAlternatives[index + 1].image = response.data
        })
        .catch(error => console.error(error))
    },
    getDeliveryDay (deliveryDayId) {
      return DELIVERIES_LIST.find((item) => item.id === deliveryDayId).title
    },
    getDeliveryTime (deliveryTime) {
      return moment.utc(deliveryTime, 'HH:mm').local().format('HH:mm')
    },
    async resetCampaign () {
      try {
        this.$store.commit('TOGGLE_LOADING')
        await this.$store.dispatch('nativeCampaignManagement/resetCampaign', this.item.campaignId)
        this.getItem()
      } catch (error) {
        console.error(error)
        NotifyService.setErrorMessage(error)
      } finally {
        this.$store.commit('TOGGLE_LOADING')
      }
    },
    goToEdit () {
      this.$router.push(`/nativeCampaignManagement/${this.item.campaignId}/edit`)
    },
    goToDuplicate () {
      this.$router.push(`/nativeCampaignManagement/${this.item.campaignId}/duplicate`)
    }
  },
  mounted () {
    this.getItem()
  }
}
</script>

<style lang="scss" scoped>

.alternating-campaigns-header {
  color: #bbb;
  font-weight: 100;
}

th{
  width: 25%;
}
</style>

export const DELIVERIES_LIST = [
  {
    id: 'MONDAY',
    title: 'Pondelok'
  },
  {
    id: 'TUESDAY',
    title: 'Utorok'
  },
  {
    id: 'WEDNESDAY',
    title: 'Streda'
  },
  {
    id: 'THURSDAY',
    title: 'Štvrtok'
  },
  {
    id: 'FRIDAY',
    title: 'Piatok'
  },
  {
    id: 'SATURDAY',
    title: 'Sobota'
  },
  {
    id: 'SUNDAY',
    title: 'Nedeľa'
  },
  {
    id: 'WEEKEND',
    title: 'Víkend'
  }
]

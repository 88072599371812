export const CATEGORIES_LIST = [
  {
    id: 'alko_nealko',
    title: 'Alko-nealko'
  },
  {
    id: 'auto_moto',
    title: 'Auto-moto'
  },
  {
    id: 'beauty',
    title: 'Beauty'
  },
  {
    id: 'business',
    title: 'Business'
  },
  {
    id: 'dom_a_zahrada',
    title: 'Dom a záhrada'
  },
  {
    id: 'fashion',
    title: 'Fashion'
  },
  {
    id: 'financie_banky_a_investicie',
    title: 'Financie, banky a investície'
  },
  {
    id: 'financie_poistenie',
    title: 'Financie a poistenie'
  },
  {
    id: 'gambling',
    title: 'Gambling'
  },
  {
    id: 'it_a_technologie',
    title: 'IT a technológie'
  },
  {
    id: 'komunikacia',
    title: 'Komunikácia'
  },
  {
    id: 'kozmetika',
    title: 'Kozmetika'
  },
  {
    id: 'potraviny',
    title: 'Potraviny'
  },
  {
    id: 'pracovny_trh',
    title: 'Pracovný trh'
  },
  {
    id: 'reality',
    title: 'Reality'
  },
  {
    id: 'reklama_a_marketing',
    title: 'Reklama a marketing'
  },
  {
    id: 'sietovy_distributor',
    title: 'Sieťový distribútor'
  },
  {
    id: 'spotrebna_elektronika',
    title: 'Spotrebná elektronika'
  },
  {
    id: 'sport',
    title: 'Šport'
  },
  {
    id: 'telco',
    title: 'Telekomunikácie'
  },
  {
    id: 'volny_cas_a_zabava',
    title: 'Voľný čas a zábava'
  },
  {
    id: 'zdravie',
    title: 'Zdravie'
  }
]

export const CATEGORIES_MAP = {
  alko_nealko: 'Alko-nealko',
  auto_moto: 'Auto-moto',
  beauty: 'Beauty',
  business: 'Business',
  dom_a_zahrada: 'Dom a záhrada',
  fashion: 'Fashion',
  financie_banky_a_investicie: 'Financie, banky a investície',
  financie_poistenie: 'Financie a poistenie',
  gambling: 'Gambling',
  it_a_technologie: 'IT a technológie',
  komunikacia: 'Komunikácia',
  kozmetika: 'Kozmetika',
  potraviny: 'Potraviny',
  pracovny_trh: 'Pracovný trh',
  reality: 'Reality',
  reklama_a_marketing: 'Reklama a marketing',
  sietovy_distributor: 'Sieťový distribútor',
  spotrebna_elektronika: 'Spotrebná elektronika',
  sport: 'Šport',
  telco: 'Telekomunikácie',
  volny_cas_a_zabava: 'Voľný čas a zábava',
  zdravie: 'Zdravie'
}
